import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OnboardingContracting } from 'src/app/shared/models/onboarding.models';
import { OnboardingApiService } from '../http/onboarding-api.service';

@Injectable({
  providedIn: 'root'
})
export class ContractingCompletedResolver implements Resolve<OnboardingContracting | null> {

  constructor(private onboardingApiService: OnboardingApiService) {
  }

  resolve(): Observable<OnboardingContracting | null> {
    return this.onboardingApiService.getUserContracting().pipe(catchError(() => of(null)));
  }
}
