import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OnboardingContracting, OnboardingAgentLicensesOptOut, OnboardingTermsOfUse } from 'src/app/shared/models/onboarding.models';

@Injectable({
  providedIn: 'root'
})
export class OnboardingApiService {

  constructor(private http: HttpClient) { }

  getUserContracting(): Observable<OnboardingContracting> {
    return this.http.get<OnboardingContracting>('/api/UserOnboarding/Contracting');
  }

  updateContractingCompleted(): Observable<null> {
    return this.http.put<null>('/api/UserOnboarding/Contracting', null);
  }

  getUserAgentLicensesOptOut(): Observable<OnboardingAgentLicensesOptOut> {
    return this.http.get<OnboardingAgentLicensesOptOut>('/api/UserOnboarding/AgentLicensesOptOut');
  }

  optOutOfAgentLicense(): Observable<any> {
    return this.http.post<boolean>('/api/UserOnboarding/AgentLicensesOptOut', null);
  }

  getLoggedInUserTermsOfUse(): Observable<OnboardingTermsOfUse> {
    return this.http.get<OnboardingTermsOfUse>('/api/UserOnboarding/TermsOfUse/LoggedInUser');
  }

  getTermsOfUse(): Observable<OnboardingTermsOfUse> {
    return this.http.get<OnboardingTermsOfUse>('/api/UserOnboarding/TermsOfUse');
  }

  acceptTermsOfUse(): Observable<OnboardingTermsOfUse> {
    return this.http.post<OnboardingTermsOfUse>('/api/UserOnboarding/TermsOfUse', null);
  }
}
