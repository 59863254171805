<section class="flex flex-col flex-grow w-full h-full">
  <section class="flex flex-col flex-grow overflow-auto p-30">
    <p class="m-auto text-lg" [innerHtml]="logoutMessage"></p>
  </section>
  <div class="flex flex-none p-20 mt-auto border-t border-solid border-gray-lighter">
    <button appCyData="logoutConfirmBtn" mat-button type="button" 
      class="m-10 button is-primary is-inverted" [mat-dialog-close]="false" (click)="logout()">Log Out</button>
    <button mat-button type="button" 
      class="m-10 ml-auto button is-primary" [mat-dialog-close]="true" (click)="refreshToken()">Remain Logged In</button>
  </div>
</section>