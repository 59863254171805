import { NavItem } from '../models/NavItem';

export const onboardingSteps:NavItem[] =[
  {
    Id: 0,
    Name: 'Welcome',
    Url: 'Welcome',
    Description: 'Let\'s get started!',
    DescriptionLong: '',
    TaskCompleted: false,
  },
  {
    Id: 1,
    Name: 'Terms of Use',
    Url: 'TermsOfUse',
    Description: 'Knock out the formalities',
    DescriptionLong: '',
    TaskCompleted: false,
  },
  {
    Id: 2,
    Name: 'Agreement',
    Url: 'Agreement',
    Description: 'Knock out the formalities',
    DescriptionLong: '',
    TaskCompleted: false,
  },
  {
    Id: 3,
    Name: 'Platform Configuration',
    Url: 'PlatformConfiguration',
    Description: 'Embrace the evolution of your business',
    DescriptionLong: '',
    TaskCompleted: false,
  },
  {
    Id: 4,
    Name: 'Carrier Contracting',
    Url: 'CarrierContracting',
    Description: 'Get ready to write your first case',
    DescriptionLong: 'Get contracted and ready to write your first case.',
    TaskCompleted: false,
  },
  {
    Id: 5,
    Name: 'Portal',
    Url: 'PortalPreview',
    Description: '',
    DescriptionLong: '',
    TaskCompleted: false,
  }
];