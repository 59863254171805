import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { MasterAdvisorAgreementRequiredModel } from 'src/app/shared/models/agreement.models';
import { AgreementsApiService } from '../http/agreements-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserMasterAdvisorAgreementRequiredResolver implements Resolve<Observable<MasterAdvisorAgreementRequiredModel | null>> {

  constructor(private agreementsApi: AgreementsApiService) { }

  resolve(): Observable<MasterAdvisorAgreementRequiredModel | null> {
    return this.agreementsApi.getMasterAdvisorAgreementRequiredRecord();
  }
}