import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';

@NgModule({ exports: [], imports: [CommonModule], providers: [CookieService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  },
  {
    provide: Window,
    useValue: window
  }, provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule { }
